import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import OwlCarousel from "react-owl-carousel2";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ArabicGetInTouch from "../../Components/GetInTouch/ArabicGetInTouch";
import Logos from "../../Components/Logos/Logos";

export default function Index(props) {
  const { jobsList } = props;

  const options = {
    items: 5,
    nav: true,
    rewind: true,
    autoplay: false,
    loop: true,
    navText: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 3,
        nav: true,
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
      },
    },
  };

  const TestCarousel = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true,
    loop: true,
    navText: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: true,
      },
      1000: {
        items: 1,
        nav: true,
        loop: false,
      },
    },
  };

  return (
    <>
      <div className="main-page-wrapper font-gordita about-wrapper">
        <section className="banner-web arab-banner">
          <div className="job-banner-five">
            <div className="container-fluid">
              <div className="row ml-0 mx-0 align-items-center Hr-Rw">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 arabCol1 Hr-BCol1 text-right">
                  <h1 className="font-recoleta arabHead about-heading">
                    {/* عملك في متناول يدك */}
                    فرص عمل في متناول يدك
                  </h1>
                  <p className="ab-sub-heading arabSHead">
                    أنت تستحق أفضل من موقع توظيف عادي
                  </p>
                  <p className="ab-over-desc arabSDesc">
                    {/* دورعلى فرص lلعمل اللي تناسبك من أحدث الوظائف المتاحة في مصر   */}
                    دورعلى فرص العمل اللي تناسبك من أحدث الوظائف الخالية في مصر
                  </p>
                  <div
                    className="scroll-down svg Hr-window"
                    id="home-scroll-down"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 25.166666 37.8704414"
                      width="40"
                      height="100%"
                      fill="none"
                      style={{
                        fillRrule: "evenodd",
                        clipRule: "evenodd",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: "1.5",
                      }}
                    >
                      <path
                        className="stroke"
                        fill="none"
                        stroke="#c7c4b8"
                        strokeWidth="20px"
                        d="M12.5833445 36.6204414h-0.0000229C6.3499947 36.6204414 1.25 31.5204487 1.25 25.2871208V12.5833216C1.25 6.3499947 6.3499951 1.25 12.5833216 1.25h0.0000229c6.2333269 0 11.3333216 5.0999947 11.3333216 11.3333216v12.7037992C23.916666 31.5204487 18.8166714 36.6204414 12.5833445 36.6204414z"
                      />
                      <path
                        className="scroller"
                        fill="#c7c4b8"
                        d="M13.0833359 19.2157116h-0.9192753c-1.0999985 0-1.9999971-0.8999996-1.9999971-1.9999981v-5.428606c0-1.0999994 0.8999987-1.9999981 1.9999971-1.9999981h0.9192753c1.0999985 0 1.9999981 0.8999987 1.9999981 1.9999981v5.428606C15.083334 18.315712 14.1833344 19.2157116 13.0833359 19.2157116z "
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <img
                    src="../images/webp/ar-banner.webp"
                    className="img-fluid Hr-BImg"
                    alt="get-image"
                    width='661'
                    height='564'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="arabFind" id="arJob-work">
          <div className="container next-banner pt-60">
            <div className="row ml-0 mx-0 justify-content-center text-center">
              <div className="col-xl-8 col-lg-8 col-md-12 col-12 findCol1">
                <h4 className="next-title">اكتشف وظائف اليوم</h4>
                <h2 className="next-subTitle">
                  {" "}
                  لا سيرة ذاتية. الهاتف المحمول هو كل ما تحتاجه.{" "}
                </h2>
                <p className="next-desc">
                  ابحث عن وظائف خالية، وتواصل مع أصحاب العمل و اثبت مهاراتك في تطبيق واحد
                </p></div>
            </div>
          </div>
        </section>

        <section className="findContent" id="how">
          <div className="container-fluid connectMain arabConnect">
            <div className="row ml-0 mx-0 justify-content-center">
              <div className="">
                <img
                  src="../images/webp/Group4188.webp"
                  alt="get-image"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="row ml-0 mx-0 grid-divider align-items-center find-web">
              <div className="col-sm-12 col-md-6 col-lg-6 first">
                <div className="">
                  <img src="../images/webp/ar-easy.webp" className="img-fluid" alt="get-image" width='737' height='640' />
                </div>
              </div>
              <div className="vertical-divider"></div>
              <div className="vertical-cutter">
                <img
                  src="../images/job/Group-4097.png"
                  alt="get-image"
                  className="img-fluid"
                />
              </div>
              <div className="bgImg">
                <img
                  src="../images/job/Group-1887.svg"
                  alt="get-image"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="card jobCard Card1 arabCard">
                  <div className="card-body">
                    <h4 className="jobTitle aCd-Title">
                      أسهل عملية بحث عن وظيفة قمت بها على الإطلاق
                    </h4>
                    <p className="jobDesc aCd-Desc">
                      {/* دور على فرص الشغل و الوظائف الشاغرة، حدد مواعيد المقابلات،اتصل بصاحب الشركات مع سكاتشز   */}
                      دور على فرص الشغل و الوظائف الشاغرة، حدد مواعيد
                      المقابلات،اتصل بصاحب الشركات مع سكاتش
                    </p>
                  </div>
                </div>
              </div>
              <div className="bgImg2">
                <img
                  src="../images/job/Group-4191.svg"
                  alt="get-image"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="card jobCard2 Card2">
                  <div className="card-body">
                    <h4 className="jobTitle aCd-Title">
                      {/* قدم للوظيفة اللي تناسبك بمهاراتك */}
                      قم بالتقديم على الوظيفة التي تناسبك وتناسب مهاراتك
                    </h4>
                    <p className="jobDesc aCd-Desc">
                      {/* وداعا للسير الذاتية والمطالب ! حستعمل حسابك الشخصي واختبارات داخل سكاتش لتظهر مهاراتك.  */}
                      السيرة الذاتية مش مطلوبة ! حتستعمل حسابك الشخصي داخل سكاتش لتظهر مهاراتك وتتقدم للوظائف الخالية.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="">
                  <img src="../images/webp/ar-hired.webp" className="img-fluid" alt="get-image" width='182' height='183' />
                </div>
              </div>
              <div className="bgImg3">
                <img
                  src="../images/job/Group-1887.svg"
                  alt="get-image"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="">
                  <img
                    src="../images/webp/ar-connect.webp"
                    className="img-fluid"
                    alt="get-image"
                    width='691'
                    height='640'
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="card jobCard Card3 arabCard">
                  <div className="card-body">
                    <h4 className="jobTitle aCd-Title">
                      {/* تواصل مع مجموعاتك المهنية */}
                      قم بالتواصل مع من يشاركونك نفس الوظيفة
                    </h4>
                    <p className="jobDesc aCd-Desc">
                      دعم فرصتك و تجصل على احسن الوظائف من خلال نصائح سكاتش و
                      مجموعاتك
                    </p>
                  </div>
                </div>
              </div>
            </div>


            {/* find mobile */}
            <div className="find-mob">
              <div className="row ml-0 mx-0 grid-divider align-items-center">
                <div className="col-sm-12 col-md-6 col-lg-6 second">
                  <div className="card jobCard Card1 arabCard">
                    <div className="card-body">
                      <h4 className="jobTitle">
                        أسهل عملية بحث عن وظيفة قمت بها على الإطلاق
                      </h4>
                      <p className="jobDesc">
                        دور على فرص الشغل و الوظائف الشاغرة، حدد مواعيد
                        المقابلات،اتصل بصاحب الشركات مع سكاتشز
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 first">
                  <div className="">
                    <img
                      alt="get-image"
                      src="../images/webp/ar-easy.webp" width='737' height='640'
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <hr className="findLine" />
              <div className="row ml-0 mx-0 grid-divider align-items-center">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="card jobCard2 Card2 arabCard">
                    <div className="card-body">
                      <h4 className="jobTitle">
                        قدم للوظيفة اللي تناسبك بمهاراتك
                      </h4>
                      <p className="jobDesc">
                        وداعا للسير الذاتية والمطالب ! حستعمل حسابك الشخصي
                        واختبارات داخل سكاتش لتظهر مهاراتك.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="">
                    <img
                      alt="get-image"
                      src="../images/webp/ar-hired.webp"
                      className="img-fluid"

                    />
                  </div>
                </div>
              </div>
              <hr className="findLine" />
              <div className="row ml-0 mx-0 grid-divider align-items-center">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="card jobCard Card3 arabCard">
                    <div className="card-body">
                      <h4 className="jobTitle">تواصل مع مجموعاتك المهنية</h4>
                      <p className="jobDesc">
                        دعم فرصتك و احصل على احسن الوظائف من خلال نصائح سكاتش و
                        مجموعاتك
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="">
                    <img
                      alt="get-image"
                      src="../images/webp/ar-connect.webp"
                      className="img-fluid"
                      width='691'
                      height='640'

                    />
                  </div>
                </div>
              </div>
            </div>
            {/* find mobile */}
          </div>
        </section>
        {jobsList?.length ? (
          <section className="ar-every">
            <div className="container-fluid connectMain">
              <div className="row ml-0 mx-0 text-center">
                <div className="col-md-12 col-12">
                  <h2 className="next-subTitle">وظائف اليوم</h2>
                </div>
              </div>
              {
                jobsList?.length ?
                  <div className="everyCarousel">
                    <OwlCarousel options={options}>
                      {jobsList?.map((n, i) => (
                        <div id="owl-slider" key={i}>
                          <div className="post-slide" style={{ height: "100%" }}>
                            <div className="post-content">
                              <h5 className="post-subTitle">{n?.company}</h5>
                              <h3 className="post-Title">
                                {n?.title ? n?.title : ""}
                              </h3>
                              {n?.salary ? (
                                <p className="post-text">
                                  <span>{n?.salary?.lowValue}</span> -
                                  <span> {n?.salary?.upperValue}</span>
                                  /شهر
                                </p>
                              ) : (
                                <p className="post-desc">&nbsp;&nbsp;</p>
                              )}
                              <hr className="postLine" />

                              {
                                n?.location ?
                                  <div className="descLoc">
                                    <div>
                                      <img
                                        src="../images/job/job-loc.png"
                                        className="img-fluid"
                                        style={{ width: "auto" }}
                                        alt="get-image"
                                      />
                                    </div>
                                    <div>
                                      <p className="post-desc" dir="rtl" style={{ direction: 'rtl' }}>{n?.location}</p>
                                    </div>
                                  </div>
                                  : null
                              }

                              {n?.openings ? (
                                <div className="descLoc">
                                  <div>
                                    <img
                                      src="../images/job/job-open.png"
                                      className="img-fluid"
                                      style={{ width: "auto" }}
                                      alt="get-image"
                                    />
                                  </div>
                                  <div>
                                    <p dir="rtl" className="post-desc" style={{ display: 'flex' }}> {n?.openings} فتحات</p>

                                  </div>
                                </div>
                              ) : (
                                <p className="post-desc">&nbsp;&nbsp;</p>
                              )}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={process.env.APP_LINK}
                                className="btn read-more"
                              >
                                تقدم للوظيفة
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                  : null
              }

            </div>
          </section>
        ) : null}

        <section className="downApp">
          <div className="container-fluid">
            <div className="row ml-0 mx-0 justify-content-center">
              <div className="col-lg-9 col-md-12 col-12">
                <div className="row ml-0 mx-0">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="downImages arDownImg">
                      <a className="gpImg" target="_blank" href={process.env.APP_LINK}>
                        <img
                          src="../images/job/gp-play.svg"
                          alt="Google Play"
                          className="img-fluid"
                        />
                      </a>

                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="arDwFlex">
                      <div className="down ar-down">
                        <h5 style={{ fontSize: "24px" }}>قم بتنزيل التطبيق</h5>
                        <img
                          src="../images/job/mobile-svgrepo-com.svg"
                          className="img-fluid"
                          alt="get-image"
                        />
                      </div>
                      <div className="leaveCol">
                        <img
                          src="../images/job/Group-4036.svg"
                          className="img-fluid"
                          alt="get-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="connect">
          <div className="container-fluid connectMain arabConnect">
            <div className="row ml-0 mx-0">
              <div className="col-lg-7 col-md-8 col-12 boxesImg">
                <div className="slider-container">
                  <div className="slider">
                    <div className="slide" data-slide="1">
                      <div className="testimonial">
                        <div className='pic'>
                          <img src="../images/webp/Mohmed.webp" className='img-fluid' alt="get-image" />
                        </div>
                        <p className="description">
                          سكاتش ساعدني في العثور على أفضل المرشحين للوظائف الشاغرة المتاحة في شركتي ، أوصي باستخدام سكاتش للتوظيف مهما كانت نوعية الشغل
                        </p>
                        <div className="testimonial-content">
                          <h5 className="name">
                            محمد علي
                          </h5>
                          <p className="ar-company">
                            Lemon AI
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="slide" data-slide="2">
                      <div className="testimonial">
                        <div className='pic'>
                          <img src="../images/webp/Ishak.webp" className='img-fluid' alt="get-image" width='73' height='73' />
                        </div>
                        <p className="description">
                          لتطبيق سكاتش العديد من الميزات الفريدة. سيكون مفيدًا جدًا لأي شركة ذات معدل دوران مرتفع نظرا ﻿لسهولة باستخدامه
                        </p>
                        <div className="testimonial-content">
                          <h5 className="name">
                            اسحق اميل
                          </h5>

                          <p className="ar-company">
                            Profile for services
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="slide" data-slide="3">
                      <div className="testimonial">
                        <div className='pic'>
                          <img src="/images/webp/testimonial-img/ahmed-adel-taha.webp" className='img-fluid' alt="get-image" width='73' height='73' />
                        </div>
                        <p className="description">
                          يوفر Skatchسكاتش خيارات لا تتوفر في أي منصة أخرى. فاختبار المهارات وأسئلة الفيديو تسهل عملية فرز ملفات المرشحين للوظيفة
                        </p>
                        <div className="testimonial-content">
                          <h5 className="name">
                            احمد عادل طه
                          </h5>
                          <h6 className='cTitle'>
                            أخصائي التطوير التنظيمي
                          </h6>
                          <p className="ar-company">
                            مؤسسة الشبراوي
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="slide-toggle-container">
                    <div id="back" className="slide-toggle">
                      <img
                        src="../images/job/XMLID_gre.svg"
                        className="img-fluid webIcon"
                        alt="get-image"
                      />
                      <img
                        src="../images/job/XMLID_224_.svg"
                        className="img-fluid mbIcon"
                        alt="get-image"
                      />
                    </div>
                    <div id="forvard" className="slide-toggle">
                      <img
                        src="../images/job/XMLID_orange_.svg"
                        className="img-fluid webIcon"
                        alt="get-image"
                      />
                      <img
                        src="../images/job/XMLID-1.svg"
                        className="img-fluid mbIcon"
                        alt="get-image"
                      />
                    </div>
                  </div>
                </div>
                {/* for mobile testimonial */}
                <div className="TestCarousel container arabTestimonial">
                  <OwlCarousel options={TestCarousel}>
                    <div id="owl-slider">
                      <div className="post-slide">
                        <div className="post-content">
                          <div className='pic'>
                            <img src="/images/webp/testimonial-img/ahmed-adel-taha.webp" className='img-fluid' alt="get-image" width='73' height='73' />
                          </div>
                          <p className="description">
                            يوفر Skatchسكاتش خيارات لا تتوفر في أي منصة أخرى. فاختبار المهارات وأسئلة الفيديو تسهل عملية فرز ملفات المرشحين للوظيفة

                          </p>
                          <div className="testimonial-content">
                            <h5 className="name">
                              احمد عادل طه

                            </h5>
                            <h6 className='cTitle'>
                              أخصائي التطوير التنظيمي
                            </h6>
                            <p className="ar-company">
                              مؤسسة الشبراوي
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="owl-slider">
                      <div className="post-slide">
                        <div className="post-content">
                          <div className='pic'>
                            <img src="../images/webp/Ishak.webp" className='img-fluid' alt="get-image" width='73' height='73' />
                          </div>
                          <p className="description">
                            لتطبيق سكاتش العديد من الميزات الفريدة. سيكون مفيدًا جدًا لأي شركة ذات معدل دوران مرتفع نظرا ﻿لسهولة باستخدامه
                          </p>
                          <div className="testimonial-content">
                            <h5 className="name">
                              اسحق اميل
                            </h5>
                            <p className="ar-company">
                              Profile for services
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-12">
                <div className="arabTest">
                  <h3 className="conTitle">
                    مواهبك تفتح لك فرص عمل
                    {/* ربط الناس وليس الوثائق */}
                  </h3>
                  <p className="conDesc">
                    موثوق به من قبل أصحاب الشركات ومفضل عند الباحثين عن عمل
                    {/* موثوق به من قبل أرباب العمل ، ومحبوب من قبل المرشحين */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="get Hr-get" id="get-in-touch">
          <div className="container-fluid connectMain">
            <div className="row ml-0 mx-0 getRw justify-content-end">
              <h2 className="next-subTitle">!ابقى على تواصل</h2>
            </div>
            <ArabicGetInTouch />
          </div>
        </section>

        <section className="blog arBlog">
          <div className="container-fluid connectMain">
            <div className="row ml-0 mx-0 text-center">
              <div className="col-md-12 col-12">
                <h2 className="next-subTitle">المدونات</h2>
              </div>
            </div>
            <div className="row mx-0 ml-0 blogRw">
              <div className="col-md-4 col-12">
                <div className="blogCard">
                  <div className="blogImg">
                    <img
                      src="../images/webp/blog-img1.webp"
                      className="img-fluid"
                      alt="get-image"
                      width={179}
                      height={128}
                    />
                  </div>
                  <div className="blogBody">
                    <h6 className="blog-subTitle">الباحثين عن عمل</h6>
                    <div className="deskbody1">
                      <h4 className="blogTitle">
                        10 أسئلة إنترفيو للفوز في مقابلة العمل
                      </h4>
                      <p className="blogText">
                        إن التحضير الجيّد للاجابة على أسئلة إنترفيو هو الأداة السحرية للفوز في مقابلة العمل. فسواء كنت موظفًا جديدًا أو حديث التخرّج أو خبيرًا في مجالك، فيجب عليك التحضير الجيّد لمقابلات العمل باتباع بعض الإرشادات الرئيسية، مثل البحث
                      </p>
                      <div className="blog-Flex">
                        <p className="blogDate">03 كانون الثاني 2023</p>
                        <a href="https://blog.skatch.com/%d8%a3%d9%87%d9%85-10-%d8%a3%d8%b3%d8%a6%d9%84%d8%a9-%d8%a5%d9%86%d8%aa%d8%b1%d9%81%d9%8a%d9%88-%d9%85%d9%82%d8%a7%d8%a8%d9%84%d8%a9-%d8%a7%d9%84%d8%b9%d9%85%d9%84/" target="_blank" className="blog-read">
                          اقرأ أكثر
                          <img
                            src="../images/job/up-arrow.svg"
                            className="img-fluid ml-2"
                            alt="get-image"
                            width={21}
                            height={21}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="blogCard">
                  <div className="blogImg">
                    <img
                      src="../images/webp/blog-img2.webp"
                      className="img-fluid"
                      alt="get-image"
                      width={179}
                      height={128}
                    />
                  </div>
                  <div className="blogBody">
                    <h6 className="blog-subTitle">الباحثين عن عمل</h6>
                    <div className="deskbody1">
                      <h4 className="blogTitle">
                        أكثر اخطاء السيرة الذاتية شيوعاً
                      </h4>
                      <p className="blogText">
                        اخطاء السيرة الذاتية كفيلة بإفساد فرصة قبولك في العمل. فالسيرة الذاتية عبارة عن لقاء مع أحد أصحاب العمل مدته 10 ثواني فقط. ! اذ  يتلقى اصحاب العمل عشرات المطالب لكل وظيفة, فيستعملون السيرة الذاتية كاداة لاختيار اولي و سريع للمرشحين
                      </p>
                      <div className="blog-Flex">
                        <p className="blogDate">03 كانون الثاني 2023</p>
                        <a href="https://blog.skatch.com/%d8%a3%d9%83%d8%ab%d8%b1-%d8%a7%d8%ae%d8%b7%d8%a7%d8%a1-%d8%a7%d9%84%d8%b3%d9%8a%d8%b1%d8%a9-%d8%a7%d9%84%d8%b0%d8%a7%d8%aa%d9%8a%d8%a9-%d8%b4%d9%8a%d9%88%d8%b9%d8%a7%d9%8b/" target="_blank" className="blog-read">
                          اقرأ أكثر
                          <img
                            src="../images/job/up-arrow.svg"
                            className="img-fluid ml-2"
                            alt="get-image"
                            width={21}
                            height={21}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="blogCard">
                  <div className="blogImg">
                    <img
                      src="../images/webp/blog-img3.webp"
                      className="img-fluid"
                      alt="get-image"
                      width={179}
                      height={128}
                    />
                  </div>
                  <div className="blogBody">
                    <h6 className="blog-subTitle">الباحثين عن عمل</h6>
                    <div className="deskbody1">
                      <h4 className="blogTitle">
                        خمسة من أهم انواع الانترفيو .. حتى تتمكن من الاستعداد لها والتفوق فيها
                      </h4>
                      <p className="blogText">
                        تجلس في منزلك سعيدًا بعد تحديد موعد الانترفيو الخاص بك، ثم تبدأ بالاستعداد له، ولكن هل تعرف انواع الانترفيو حتى تتمكن من الاستعداد لها جيدًا ؟
                        وذلك لأن كل نوع من انواع المقابلات الشخصية يتطلب استعدادًا مختلفًا
                      </p>
                      <div className="blog-Flex">
                        <p className="blogDate">03 كانون الثاني 2023</p>
                        <a href="https://blog.skatch.com/%d8%a3%d9%87%d9%85-%d8%a7%d9%86%d9%88%d8%a7%d8%b9-%d8%a7%d9%84%d8%a7%d9%86%d8%aa%d8%b1%d9%81%d9%8a%d9%88/" target="_blank" className="blog-read">
                          اقرأ أكثر
                          <img
                            src="../images/job/up-arrow.svg"
                            className="img-fluid ml-2"
                            alt="get-image"
                            width={21}
                            height={21}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="arFaq">
          <div className="container-fluid connectMain">
            <div className="row mx-0 ml-0 justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10 col-12">
                <h2 className="next-subTitle text-center">أسئلة مكررة</h2>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordionHeader">
                      ما هو سكاتش؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      <p>
                        سكاتش هي منصة احترافية تربط الباحثين عن شغل في مصر
                        بأصحاب الشركات و مسئولي التوظيف
                        <br />
                        سكاتش ليس شركة توظيف.
                        <br />
                        نحن نساعد الباحثين عن فرصة عمل على التقدم للوظائف
                        والتحدث مع مسئولي التوظيف ومديري الشركات بشكل مباشر
                        والعكس صحيح.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="accordionHeader">
                      هل سكاتش حقيقي؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      سكاتش صادق غير زائف. نحن لا نتسامح مع أصحاب العمل المزيفين
                      والباحثين عن فرصة عمل الذين يتسببون في مشاكل للآخرين أو
                      يحاولون جمع المعلومات.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="accordionHeader">
                      إزاي سكاتش بتستخدم معلوماتي الشخصية؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      بياناتك موجودة على التطبيق بكل سرية مش بنستخدمها غير اننا
                      نجيبلك شغل وبس .
                      <br />
                      يتم تخزين بياناتك الشخصية ولا تتم مشاركتها مع أي شخص آخر
                      بخلاف داخل التطبيق وبإذن منك وبس.
                      <br />
                      لمزيد من التفاصيل اقرأ سياسة الخصوصية الخاصة بنا.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="accordionHeader">
                      إزاي أحصل على فرص عمل باستخدام سكاتش؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      <p>
                        من السهل استخدام سكاتش. اتبع الخطوات ذه للحصول على وظيفة
                        باستخدام تطبيق سكاتش
                      </p>
                      <ul style={{ listStyle: "none", paddingLeft: "15px" }}>
                        <li>قم بتحميل سكاتش من متجر Google Play</li>
                        <li>
                          تسجيل الدخول باستخدام رقم الموبايل وإنشاء ملف التعريف
                          الخاص بك
                        </li>
                        <li>اختر فئات الوظائف التي تتناسب مع مهاراتك.</li>
                        <li>ابحث عن وظائف خالية وقم بالتقديم.</li>
                        <li>إذا تم اختيارك ، حدد موعد المقابلة.</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header className="accordionHeader">
                      هل مطلوب مني أدفع رسوم لاستخدام سكاتش؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      سكاتش مجاني للباحثين عن فرصة عمل.
                      <br />
                      يمكنك تحميل تطبيق سكاتش ، وإنشاء ملف التعريف الخاص بك ،
                      والتقدم إلى أي وظائف على المنصة. ما فيش أي مدفوعات لبحثك
                      عن شغل.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header className="accordionHeader">
                      إزاي يمكن تساعدوني في العثور على وظائف خالية؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      يختار لك سكاتش الوظائف الشاغرة التي تتناسب مع مؤهلاتك.
                      يمكنك تتقدم للوظائف مجانًا.
                      <br />
                      يساعدك سكاتش على إنشاء سيرة ذاتية احترافية وإضافة المهارات
                      إلى ملفك الشخصي والعثور على فرصة عمل مناسبة لك.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header className="accordionHeader">
                      أنا سجلت وقدمت على وظيفة. ليه محدش من الشركة اتصل بي لحد
                      دلوقتي؟
                    </Accordion.Header>
                    <Accordion.Body className="accordionBody">
                      لما تقدم على وظيفة، مطلبك يروح إلى صاحب العمل.
                      <br />، يتلقى صاحب العمل في كل وظيفة طلبات كثيرة ، فيحتاج
                      إلى وقت لاختيار المرشحين . طبعا سكاتش حيوصلك رد في جميع
                      الحالات حتى لو بالرفض .و لو تم اختيارك بنجاح ، سيتصل بك
                      صاحب العمل من خلال سكاتش لإجراء مقابلة.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <div className="row mx-0 ml-0 faqCopy">
                  <p className="faqDesc">
                    <a

                      href="#get-in-touch"
                      style={{
                        color: "#D56C33",
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                    >
                      مركز المساعدة
                    </a>{" "}&nbsp;
                    لا يزال عندك سؤال؟ زر
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="borderBottom">
          <hr className="jbBorder"></hr>
        </section>
      </div>
    </>
  );
}
