import React from "react";
import Link from "next/link";
function ArabicHeader(props) {
  const { dynamicSlug } = props;
  const HR_PORTAL_ARABIC_URL = `https://hr.skatch.com/`;
  return (
    <>
      <div className="theme-main-menu sticky-menu theme-menu-five arabic-header job-menu">
        <div className="">
          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <button
              role="button"
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarColor02"
              aria-controls="navbarColor02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>{" "}
            </button>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
              style={{ flexDirection: "row-reverse" }}
            >
              <ul
                className="navbar-nav mr-auto main-side-nav font-gordita"
                id="one-page-nav"
              >
                <li className="nav-item">
                  {/* <a href="#job-work" className="nav-link">How it Works</a> */}
                  <a
                    href="/#arJob-work"
                    className="nav-link"
                    style={{ textDecoration: "none" }}
                  >
                    <span>بحث الوظائف</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href={dynamicSlug} className="nav-link">
                    <span>English</span>
                  </a>
                </li>
              </ul>
              <div className="right-widget">
                <a
                  href={process.env.APP_LINK}
                  target="_blank"
                  className="hire-button mr-2"
                  style={{
                    backgroundColor: "#d56c33",
                    color: "#fff",
                    fontWeight: "650",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/icon/119.svg"
                      alt="as"
                      width="28"
                      height="15"
                      style={{ transform: "rotate(180deg)" }}
                    />
                    &nbsp; نزل التطبيق
                  </span>
                </a>
                <a
                  href={HR_PORTAL_ARABIC_URL}
                  className="want-button mt-3 mt-lg-0 mt-md-0 mt-sm-0"
                >
                  <span>صاحب شركة</span>
                </a>

                {/* <a href={dynamicSlug} className="want-button" style={{border: 'none', fontWeight: '650',cursor:'pointer',paddingLeft: '15px', paddingRight: '15px'}}>
                          <span>
                            English
                          </span> 
                        </a> */}
              </div>
            </div>
            <div className="" style={{ cursor: "pointer" }}>
              <Link
                href="/"
                className="cursor navbar-brand"
                style={{ cursor: "pointer" }}
              >
                <img
                  src="../images/job/arabic-logo.svg"
                  alt="skatch"
                  width="163"
                  height="40"
                />
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
export default ArabicHeader;
