import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

function ArabicGetInTouch() {

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone, message } = e.target.elements;
    const response = await fetch("/api/sendgrid", {
      body: JSON.stringify({
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((res) => res.json());
    if (response.success) {
      toast.success(response.message);
      document.getElementById("getform").reset();
      document.getElementById("close").click();
    } else {
      toast.error(response.message);
      document.getElementById("close").click();
    }
  }

  return (
    <>
      <div>
          <div className='row mx-0 ml-0 formRw'>         
            <div className='col-lg-6 col-md-12 col-12'>
              <img src="../images/webp/get-image.webp" className='img-fluid formImg' width='623' height='347' alt="get-image" />
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
                <div>
                  <form onSubmit={(e) => handleSubmit(e)} id="getform" className='arForm'>
                      <div className="messages"></div>
                     <div className='row controls mx-0 ml-0'>
                        <div className='col-md-12'>
                            <div className="input-block">
                                <input type='text' name="name" required="required" id='firstname' data-error="Name is required." />
                                <label className="placeholder" htmlFor='firstname'>
                                *الاسم الكامل
                                </label>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="input-block">
                                <input type='email' name="email" required="required" id='email' data-error="Valid email is required." />
                                <label className="placeholder" htmlFor='email'>
                                *البريد الإلكتروني
                                </label>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="input-block">
                                <input type='phone' name="phone" required="required" id='phone' data-error="Valid phone number is required." />
                                <label className="placeholder" htmlFor='phone'>
                                *رقم الهاتف
                                </label>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="input-block">
                                <textarea type='message' name="message" id='message' rows="4" cols="50" required="required" data-error="Please,leave us a message." />
                                <label className="placeholder" htmlFor='message'>
                                *رسالة
                                </label>
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>
                     </div>
                      <div className="pr-3 float-right">
                        <button  role='button' className="filebtn1" type="submit" name="sendmail">
                        إرسال رسالة
                        </button>
                      </div>
                  </form>
                  
                </div>
            </div>
          </div>
      </div>
      <Toaster position='bottom-center' />
    </>
  )
}
export default ArabicGetInTouch;