import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useState } from "react";
import ArabicFooter from "../Components/Footer/ArabicFooter";
import ArabicHeader from "../Components/Header/ArabicHeader";
import ArabicJobApp from "../Sections/JobSection/ArabicJobApp";
import { getMethod, setAuthorizationLang } from "../utils/services";


export default function Index(props) {
  const { jobsList } = props;
  const router = useRouter();
  setAuthorizationLang("ar");
  const [latestJobs, setLatestJobs] = useState(
    jobsList?.length ? jobsList : []
  );

  useEffect(() => {
    setAuthorizationLang("ar");
    getMethod("/job/getLatestJobs").then((jobsLists) => {
      if (jobsLists?.success) {
        setLatestJobs(jobsLists.data?.length ? jobsLists.data : []);
      }
    });
  }, [jobsList]);
  return (
    <>
      <Head>
        <title>سكاتش _فرص عمل ووظائف خالية</title>
        <meta
          name="description"
          content="سكاتش منصة توظيف توفر مجانيا وظائف خالية فى كل المجالات وفرص عمل لكل المهارات و الخبرات"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="سكاتش _فرص عمل ووظائف خالية" />
        <meta
          property="og:description"
          content="سكاتش منصة توظيف توفر مجانيا وظائف خالية فى كل المجالات وفرص عمل لكل المهارات و الخبرات"
        />
        <meta name="twitter:title" content="سكاتش _فرص عمل ووظائف خالية" />
        <meta
          name="twitter:description"
          content="سكاتش منصة توظيف توفر مجانيا وظائف خالية فى كل المجالات وفرص عمل لكل المهارات و الخبرات"
        />
      </Head>
      <div className="main-page-wrapper font-gordita about-wrapper">

        <ArabicHeader dynamicSlug={"en" + router?.route} />
        <ArabicJobApp jobsList={latestJobs?.length ? latestJobs : []} />
        <ArabicFooter />
      </div>
    </>
  );
}

export async function getServerSideProps(context) {
  setAuthorizationLang("ar");
  var latestJob = [];
  const jobs = await getMethod("/job/getLatestJobs");
  if (jobs?.success) {
    latestJob = jobs.data?.length ? jobs.data : [];
  }
  return {
    props: {
      jobsList: latestJob,
    }, // will be passed to the page component as props
  };
}
